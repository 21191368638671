import React from 'react';
import Layout from '../components/Layout';

require('es6-promise').polyfill();

export default function HomepagePagePage({
  pageContext: { getContentDataRows, config },
  props,
}) {
  return (
    <Layout {...props} title="Sitemap">
      <div className="home">
        <section className="app-content"></section>
      </div>
    </Layout>
  );
}
